table.table-sales {
    width: 100%;
    border-collapse: collapse;
}
table.table-sales td, table.table-sales th {
    border: 1px solid #ddd;
    padding: 8px;
    color: #fff;
    font-size: 13px;
}
tr.table-header.table-header-sales td {
    background-color: #028fc5;
    text-align: center;
    font-size: 13px;
}

table.table-sales tr:nth-child(even){background-color: #292929;}

table.table-sales tr:hover {background-color:#002e40;}

button.btn-refresh {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    background-color: #4a4a4a;
    outline: none;
    cursor: pointer;
}
button.btn-refresh:hover {
    background-color: #292929;
}
.td-money{
    min-width: 70px;
}
.td-center{
    text-align: center;
}