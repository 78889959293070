.MuiInputBase-root {
    padding: 0px !important;
}

.slider-ratio {
    aspect-ratio: 1/0.5625 !important;
    min-height: fit-content;
    overflow: hidden;
}

.slider-item {
    width: 100%;
    background-size: cover;
    background-position: center;
    aspect-ratio: 1/0.5625 !important;
}

input {
    box-shadow: none !important;
    border: 0px !important;
    outline: 0px !important;
    padding: 10px !important;
    font-size: 14px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

textarea {
    box-shadow: none !important;
    border: 0px !important;
    outline: 0px !important;
    padding: 10px !important;
    font-size: 14px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.h-screen {
    height: calc(100vh - 64px);
}

.card {
    padding: 8px;
    min-height: 160px;
    border-radius: 8px;
    background-color: #f2f2f2;
}

.sidemenu-container::-webkit-scrollbar {
    display: none;
}

.sidemenu-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sidemenu-ul {
    gap: 5px;
    color: #fff;
    padding: 5px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    border-radius: 0px 10px 10px 0px;
}

.sidemenu-ul li a {
    padding: 10px;
    width: 100%;
    display: block;
    border-radius: 10px;
    font-size: 13px;
}

.sidemenu-ul li a .menu-sub-item {
    display: none;
    height: 0px;
}

.sidemenu-ul li a.active .menu-sub-item {
    display: block;
    padding-left: 10px;
    padding-top: 5px;
    height: auto;
}

.sidemenu-ul li a:hover {
    /* background-color: #2b2bff; */
    /* background-color: rgb(30 64 175); */
    background-color: rgb(30 27 75);
}

.sidemenu-ul li a.active {
    padding: 10px;
    /* background-color: #0000bd; */
    background-color: rgb(23 37 84);
    width: 100%;
    display: block;
    border-radius: 10px;
}

.dashboard-container {
    height: calc(100vh - 64px);
    overflow-y: scroll;
}

.news-item-thumbnail {
    display: block;
    /* width: 320px; */
}

.avatar {
    width: 50px;
    height: 50px;
    background-color: #00f;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    color: #fff;
    background-position: center;
    background-size: cover;
}