.login-container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.login-paper{
    padding: 10px;
    min-width: 300px;
    min-height: 300px;
    margin-left: auto;
    margin-right: auto;
    background-color: #a7a7a7;
    border-radius: 5px;
}
.login-paper h1{
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 30px;
}
.inputWrapper{
    display: block;
    margin-bottom: 10px;
}
.inputWrapper label{
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 700;
}
.inputWrapper input {
    padding: 10px;
    width: 220px;
    border-radius: 5px;
    border: none;
    font-size: 14px;
}
.login-btn{
    min-width: 180px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
}